angular.module("app")
    .controller("paymentMethodPrepaidEditController", ["$scope", "$log", "$state", "$stateParams", "Notification", "paymentMethodService", "travelPolicyService", function ($scope, $log, $state, $stateParams, Notification, paymentMethodService, travelPolicyService) {

        var vm = this;
        vm.loading = false;
        vm.loadingForSave = false;
        vm.paymentMethod = undefined;
        vm.showPolicy = false;

        vm.paymentMethodReference = $stateParams.paymentMethodReference;
        vm.organisationReference = $stateParams.organisationReference;

        vm.effectivePolicy = [];
        vm.availablePolicies = [];

        (function init() {
            $log.info("initialised with reference id %o", vm.paymentMethodReference);
            bindLoaderStateEvents();
            loadPaymentMethod().then(function () {
                loadTravelPolicies();
            });
        }());

        function loadTravelPolicies() {
            if (!_.isUndefined(vm.paymentMethod.travelPolicy)) {
                vm.effectivePolicy.push(vm.paymentMethod.travelPolicy);
            }

            return travelPolicyService.list(false)
                .then(function (results) {
                    $log.debug('policies %o', results);
                    if (results) {
                        results.forEach(function (policy) {
                            if (policy.isDefault && vm.effectivePolicy.length === 0) {
                                vm.effectivePolicy.push(policy);
                            } else {
                                if (_.isUndefined(vm.paymentMethod.travelPolicy) || policy.travelPolicyReference != vm.paymentMethod.travelPolicy.travelPolicyReference) {
                                    vm.availablePolicies.push(policy);
                                }
                            }
                        });
                    }

                    if (vm.effectivePolicy.length === 0) {
                        return travelPolicyService.getDefaultPolicy()
                            .then(function (result) {
                                if (!_.isUndefined(result)) {
                                    vm.effectivePolicy.push(result);
                                    vm.availablePolicies.push({});
                                }
                            });
                    }
                    if (vm.effectivePolicy.length > 0) {
                        vm.availablePolicies.push({});
                    }
                });
        }

        vm.collapseDetail = function () {
            //vm.hideDetail = true;
            $log.debug('Hide detail ', vm.hideDetail);
        };

        function loadPaymentMethod() {
            return paymentMethodService.get(vm.organisationReference, vm.paymentMethodReference)
                .then(function (data) {
                    vm.paymentMethod = data;
                    initNotificationTypeOptions(vm.paymentMethod.account.notificationType);
                });
        }

        vm.save = function () {

            angular.forEach($scope.form.$error.required, function (field) {
                field.$setTouched();
            });

            if (!$scope.form.$valid) return;

            vm.loadingForSave = true;

            vm.paymentMethod.account.notificationType = buildNotificationType();

            var paymentMethod = angular.copy(vm.paymentMethod);
            applyTravelPolicy(paymentMethod);

            return paymentMethodService.update(vm.organisationReference, paymentMethod)
                .then(function (results) {
                    $state.go("payment-methods");
                })
                .catch(function (resp) {
                    if (resp.data.messages) {
                        _.each(resp.data.messages, function (message) {
                            Notification.error({message: message, delay: 5000});
                        });
                    } else {
                        Notification.error({
                            message: 'There was a problem updating this prepaid account, please refresh and try again',
                            delay: 5000
                        });
                    }
                }).finally(function () {
                    vm.loadingForSave = false;
                });
        };

        function applyTravelPolicy(paymentMethod) {
            if (vm.effectivePolicy.length > 0) {
                var policy = vm.effectivePolicy[0];
                if (!policy.isDefault) {
                    staff.travelPolicy = policy;
                }
            } else {
                paymentMethod.travelPolicy = undefined;
            }
        }

        vm.effectivePolicyList = {
            accept: function () {
                if (vm.effectivePolicy.length >= 1) {
                    return false;
                } else {
                    return true;
                }
            }
        };

        vm.dayInOrder = function (day) {
            return travelPolicyService.dayOfWeek(day.dayOfWeek);
        };


        function initNotificationTypeOptions(notificationType) {
            var notificationTypeOptions = paymentMethodService.initPaymentMethodNotificationTypeOptions(notificationType);
            vm.notifyOrganisation = notificationTypeOptions.notifyOrganisation;
            vm.ccPassenger = notificationTypeOptions.ccPassenger;
        }

        function buildNotificationType() {
            return paymentMethodService.buildPaymentMethodNotificationType(vm.notifyOrganisation, vm.ccPassenger);
        }

        function bindLoaderStateEvents() {
            $scope.$on('loader_show', function () {
                vm.loading = true;
            });

            $scope.$on('loader_hide', function () {
                vm.loading = false;
            });
        }

    }]);
